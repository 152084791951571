<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="card border-0 shadow-sm">
          <div class="card-body">
            <h4 class="font-weight-bold mb-4">Amchara 360°</h4>
            <div class="alert alert-success">
              <p>Book your 360 experience with Amchara here.</p>
            </div>

            <div class="text-right mb-3">
              <a
                href="https://amchara360.com"
                target="_blank"
                class="btn btn-sm btn-success"
                ><i class="far fa-link me-2"></i>Amchara 360°</a
              >
            </div>

            <div class="row">
              <div class="col">
                <form @submit.prevent>
                  <!--  -->
                  <div class="row">
                    <div class="col-md-4">
                      <div class="card border-0 bg-light shadow-sm">
                        <div class="card-header bg-light">
                          <h6 class="font-weight-bold">Click 360 Package</h6>
                        </div>
                        <div class="card-body">
                          <ul
                            class="list-group list-group-flush cursor-pointer"
                          >
                            <li
                              class="list-group-item"
                              v-for="t in availablePackages"
                              :key="t.id"
                              @click="addPackage(t)"
                            >
                              {{ t.title }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <!--  -->

                    <div class="col">
                      <div class="card border-0 bg-light shadow-sm">
                        <div class="card-body">
                          <div
                            v-if="form.packages.length == 0"
                            class="text-center"
                          >
                            <i class="far fa-circle fa-3x text-success"></i>
                            <p class="font-weight-bold">
                              The packages you choose will be listed here,
                              confirm your 360 package booking below
                            </p>
                          </div>
                          <div v-else>
                            <ul class="list-group-flush">
                              <li
                                class="list-group-item"
                                v-for="(t, key) in form.packages"
                                :key="key"
                              >
                                <div class="row">
                                  <div class="col-2 my-auto">
                                    <img
                                      :src="t.image"
                                      :alt="t.title"
                                      width="100%"
                                    />
                                  </div>
                                  <div class="col my-auto">
                                    <h6 class="font-weight-bold mb-0">
                                      {{ t.title }}
                                    </h6>
                                  </div>
                                  <div class="col-auto my-auto ms-auto">
                                    <button
                                      class="btn btn-sm btn-outline-danger"
                                      @click="removePackage(key)"
                                    >
                                      <i class="far fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <button
                        class="btn btn-success float-end mt-4"
                        :disabled="form.packages.length == 0 || busy"
                        @click="confirm"
                      >
                        <i class="far fa-check me-2"></i>Confirm 360 Package
                      </button>
                    </div>
                  </div>

                  <!--  -->
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      busy: false,
      form: {
        packages: [],
      },
      availablePackages: [
        {
          id: 1,
          title: "Gut health",
          image: "https://www.amchara360.com/images/site/brown-ring.png",
          description:
            "Your gut health assessment includes a highly comprehensive analysis of your digestive tract and gut microbiome. This helps us to objectively evaluate the status of your gut health and identify contributing factors to gastrointestinal complaints and other chronic health conditions as well as the strength of your immune system.",
        },
        {
          id: 2,
          title: "Hormones",
          image: "https://www.amchara360.com/images/site/purple-ring.png",
          description:
            "Good hormone health is fundamental to 360° health. The hormone package will examine the key pillars of hormone health; the adrenal gland, thyroid gland and sex hormones. If one pillar is overloaded or weakened, then the whole structure can be compromised. These extensive panels use combinations of dried urine, saliva swabs and blood for accurate and reliable data. Once your tests results are returned by the biomedical labs, we can review your results and support you with a complete clinical assessment before forming your bespoke plan, personalised to your lifestyle and health needs.",
        },
        {
          id: 3,
          title: "Genetics",
          image: "https://www.amchara360.com/images/site/blue-ring.png",
          description:
            "By gaining insight into your unique DNA structure, you can make informed choices to optimise your health and help to prevent chronic disease. The sample collection is straightforward, a simple saliva swab is all we need. Your results are sent to our partner laboratory and the comprehensive results will provide us with a deep analysis of your genetic makeup and gene expression. Using the results, your 360° Health Practitioner will work with you on a 1:1 basis to develop a Personalised Health plan tailored to your unique needs and goals.",
        },
        {
          id: 4,
          title: "Complete 360",
          image: "https://www.amchara360.com/images/site/360-ring.png",
          description:
            "Complete 360° brings in all of the elements together to create a unified health picture. Gut function and hormone function overlap, for example the ability of the small intestine to absorb nutrition effectively into the blood stream can impact on our adrenal function and thyroid function. Additionally, we all have a unique DNA make up. Understanding your unique genetic blueprint enables us to work to balance your hormones and help you achieve optimal digestive function that is considerate of this blueprint.",
        },
      ],
    };
  },
  methods: {
    confirm() {
      this.busy = true;
      this.$EventBus.$emit("alert", {
        message: "Your Amchara 360 booking has been confirmed.",
      });
      setTimeout(() => {
        this.$router.push("/dashboard");
      }, 1500);
    },
    removePackage(key) {
      this.form.packages.splice(key, 1);
    },
    addPackage(t) {
      this.form.packages.push(t);
      this.$EventBus.$emit("alert", { message: t.title + " added to booking" });
    },
  },
};
</script>

<style></style>
